import React, { useState } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Modal, ModalBody, Row } from "reactstrap"
import Spinner from "components/Common/Spinner"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { overwriteOrderDetails } from "store/actions"
import ConfirmModal from "./ConfirmModal"

const OrderEditModal = ({ onCloseClick, show, showModal }) => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => ({
    loading: state.Users.userCreateLoad,
    error: state.Users.error,
  }))
  const orderDetail = showModal?.data
  const [overratedOrderDetails, setOverratedOrderDetails] = useState({})

  const handleValidSubmit = value => {
    setOverratedOrderDetails(value)
    setConfirmAgain(true)
  }
  const paymentTypeOptions = [
    { label: "Online Payment", value: "Online Payment" },
    { label: "Cash on Delivery", value: "Cash on Delivery" },
    // Add more options as needed
  ]

  const [confirmAgain, setConfirmAgain] = useState(false)

  const handleOverwriteOrderConfirmed = () => {
    setConfirmAgain(false)
    const value = overratedOrderDetails

    const orderEditData = {
      orderId: orderDetail?._id,
      paymentType: value.paymentType,
      shippingInfo: {
        name: value.name,
        address: value.address,
        addressLine1: value.addressLine1,
        addressLine2: value.addressLine2,
        street: value.street,
        landmark: value.landmark,
        area: value.area,
        district: value.district,
        state: value.state,
        pinCode: value.pinCode,
        email: value.email,
        contactNumber: value.contactNumber,
        whatsappNumber: value.whatsappNumber,
      },
      shippingPrice: parseInt(value.shippingPrice),
      codCharge: parseInt(value.codCharge),
      itemsPrice: parseInt(value.itemsPrice),
      totalDiscount: parseInt(value.totalDiscount),
      couponDiscount: parseInt(value.couponDiscount),
      taxValue: parseInt(value.taxValue),
      totalPrice: parseInt(value.totalPrice),
    }
    dispatch(overwriteOrderDetails(orderEditData, onCloseClick))
  }

  return (
    <>
      <ConfirmModal
        show={confirmAgain}
        onCloseClick={() => setConfirmAgain(false)}
        onConfirmClick={handleOverwriteOrderConfirmed}
      />
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        style={deleteModalStyle}
        className="delete-modal modaledit"
      >
        <ModalBody style={deleteModalBodyStyle} className="p-4">
          {showModal?.data?._id && (
            <AvForm
              className="form-horizontal "
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              {error?.length > 1 ? <Alert color="danger">{error}</Alert> : null}
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5 className="font-weight-bolder text-black mb-3">
                    Overwrite Order Detail: #{orderDetail?.orderId}
                  </h5>

                  <p>User Name : {orderDetail?.shippingInfo?.name}</p>
                </div>
                <Row>
                  <div className="d-flex justify-content-start mb-1">
                    <h6 className="font-weight-bolder text-muted">
                      Delivery Address :
                    </h6>
                  </div>
                  <Col sm="12">
                    <AvField
                      value={orderDetail?.shippingInfo?.name}
                      name="name"
                      className="w-100 mb-3"
                      type="text"
                      label="Name"
                    />
                  </Col>
                  <Col sm="12">
                    <AvField
                      value={orderDetail?.shippingInfo?.address}
                      name="address"
                      className="w-100 mb-3 custom-textarea"
                      type="textarea"
                      label="Address"
                    />
                  </Col>
                  {orderDetail?.shippingInfo?.addressLine1 && (
                    <Col sm="12">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.addressLine1},`}
                        name="addressLine1"
                        className="mb-3"
                        type="text"
                        label="AddressLine1"
                      />
                    </Col>
                  )}

                  {orderDetail?.shippingInfo?.addressLine2 && (
                    <Col sm="12">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.addressLine2},`}
                        name="addressLine2"
                        type="text"
                        label="AddressLine2"
                        className="mb-3"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.street && (
                    <Col sm="6">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.street},`}
                        name="street"
                        type="text"
                        className="mb-3"
                        label="Street"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.landmark && (
                    <Col sm="6">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.landmark},`}
                        name="landmark"
                        type="text"
                        className="mb-3"
                        label="Landmark"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.area && (
                    <Col sm="6">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.area},`}
                        name="area"
                        type="text"
                        className="mb-3"
                        label="Area"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.district && (
                    <Col sm="6">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.district}`}
                        name="district"
                        type="text"
                        label="District"
                        className="mb-3"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.state && (
                    <Col sm="6">
                      <AvField
                        value={`${orderDetail?.shippingInfo?.state}`}
                        name="state"
                        type="text"
                        className="mb-3"
                        label="State"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  {orderDetail?.shippingInfo?.pinCode && (
                    <Col md={6}>
                      <AvField
                        value={`${orderDetail?.shippingInfo?.pinCode}`}
                        name="pinCode"
                        type="number"
                        label="Pincode"
                        className="mb-3"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.email && (
                    <Col md={6}>
                      <AvField
                        value={orderDetail?.shippingInfo?.email}
                        name="email"
                        className="mb-3"
                        type="email"
                        label="Email"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.contactNumber && (
                    <Col md={6}>
                      <AvField
                        value={orderDetail?.shippingInfo?.contactNumber}
                        name="contactNumber"
                        type="number"
                        className="mb-3"
                        label="Contact Number"
                      />
                    </Col>
                  )}
                  {orderDetail?.shippingInfo?.whatsappNumber && (
                    <Col md={6}>
                      <AvField
                        value={orderDetail?.shippingInfo?.whatsappNumber}
                        name="whatsappNumber"
                        className="mb-3"
                        type="number"
                        label="Whatsapp Number"
                      />
                    </Col>
                  )}

                  {orderDetail?.paymentType && (
                    <Col md={6}>
                      <AvField
                        name="paymentType"
                        type="select"
                        className="mb-3"
                        label="Payment Type"
                        value={orderDetail?.paymentType}
                      >
                        {paymentTypeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  )}
                </Row>
                <Row className="">
                  <div className="d-flex justify-content-start mb-1 mt-2">
                    <h6 className="font-weight-bolder text-muted">
                      Shipping Info:
                    </h6>
                  </div>
                  <Col sm="6" className="mt-1">
                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex  align-items-center">
                        <h6 className="comentytle">Item Total:</h6>
                      </div>
                      <AvField
                        value={orderDetail?.itemsPrice?.toFixed(2)}
                        name="itemsPrice"
                        type="number"
                        disabled
                      />
                    </div>
                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex   align-items-center">
                        <h6 className="comentytle">Shipping:</h6>
                      </div>
                      <AvField
                        value={orderDetail?.shippingPrice?.toFixed(2)}
                        name="shippingPrice"
                        type="number"
                      />
                    </div>

                    {orderDetail?.paymentType === "Cash on Delivery" && (
                      <div className="d-flex mb-2 justify-content-end">
                        <div className="d-flex align-items-center">
                          <h6 className="comentytle">COD Charge:</h6>
                        </div>
                        <AvField
                          value={orderDetail?.codCharge?.toFixed(2)}
                          name="codCharge"
                          type="number"
                        />
                      </div>
                    )}

                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex align-items-center">
                        <h6 className="comentytle">Gross Total:</h6>
                      </div>{" "}
                      <AvField
                        value={orderDetail?.totalPrice?.toFixed(2)}
                        name="totalPrice"
                        type="number"
                      />
                    </div>
                  </Col>
                  <Col sm="6" className="mt-1">
                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex align-items-center">
                        <h6 className="comentytle">Discount:</h6>
                      </div>{" "}
                      <AvField
                        value={orderDetail?.totalDiscount?.toFixed(2)}
                        name="totalDiscount"
                        type="number"
                      />
                    </div>
                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex align-items-center">
                        <h6 className="comentytle">Coupon Discount:</h6>
                      </div>
                      <AvField
                        value={orderDetail?.couponDiscount?.toFixed(2)}
                        name="couponDiscount"
                        type="number"
                      />
                    </div>
                    <div className="d-flex mb-2 justify-content-end">
                      <div className="d-flex align-items-center">
                        <h6 className="comentytle">Tax:</h6>
                      </div>{" "}
                      <AvField
                        value={orderDetail?.taxValue?.toFixed(2)}
                        name="taxValue"
                        type="number"
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="hstack gap-2 mt-5 d-flex align-items-center justify-content-end">
                <button
                  className="btn btn-outline-light w-25 btn-sm"
                  style={{ borderRadius: 10 }}
                  onClick={onCloseClick}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ borderRadius: 10 }}
                  className="btn btn-success w-25 btn-sm"
                >
                  Edit Order&nbsp;
                  {loading && <Spinner />}
                </button>
              </div>
            </AvForm>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

OrderEditModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  showModal: PropTypes.object,
}

export default OrderEditModal

const deleteModalStyle = {
  minWidth: 700,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
