import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import moment from "moment"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Media,
  Button,
  Badge,
  Table,
} from "reactstrap"

import { exportComponentAsJPEG } from "react-component-export-image"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  getOrders,
  getOrderDetails,
  updateOrderAwb,
  updatePayment,
  updateOrder,
  createShiprocketOrder,
  getShiprocketLabel,
  getShiprocketInvoice,
} from "store/orders/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { CurrencySwitch } from "hooks/Currency"
import OrderItemTable from "./OrderItemTable"
import MetaTag from "components/Common/Meta-tag"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { map } from "lodash"
import { Notification } from "components/Common/Notification"
import { LocalLogo } from "assets/images"
import UserAccount from "./UserAccount"
import { useCopyText } from "hooks/useCopyText"
import ShiprocketLabelPdfPopup from "./ShiprocketLabelPdfPopup"
import { FaFileInvoice, FaStickyNote, FaWhatsapp } from "react-icons/fa"
import ShiprocketInvoicePdfPopup from "./ShiprocketInvoicePdfPopup"

function OrderDetail() {
  const dispatch = useDispatch()
  const params = useParams()
  const orderDetailCompRef = useRef()

  const {
    orderDetails,
    shipping,
    logistics,
    payment,
    userDetails,
    shiprocketLabel,
    shiprocketLoad,
    shiprocketInvoice,
    shiprocketInvoiceLoad,
  } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
    shipping: state.Settings.settings.shipping,
    logistics: state.Settings.settings.logistics,
    payment: state.Settings.settings.payment,
    userDetails: state.Users.userDetails,
    shiprocketLoad: state.Orders.shiprocketLoad,
    shiprocketLabel: state.Orders.shiprocketLabel,
    shiprocketInvoiceLoad: state.Orders.shiprocketInvoiceLoad,
    shiprocketInvoice: state.Orders.shiprocketInvoice,
  }))
  console.log(payment)

  const pickupLocations = logistics && logistics?.pickupLocations

  useEffect(() => {
    dispatch(getOrders())
  }, [dispatch])

  useEffect(() => {
    dispatch(getOrderDetails(params.id))
  }, [dispatch])

  const currecny = CurrencySwitch()

  // const orderStatus = [
  //   "Processing",
  //   // "Fulfilled",
  //   "Shipped",
  //   "Delivered",
  //   "Cancelled",
  //   // "Cancel Processing",
  //   "Returned",
  //   // "Return Processing",
  // ]

  const handleOrderStatus = () => {
    if (orderDetails?.orderStatus === "Pending") {
      return [{ status: "Processing", id: 1, className: "info" }]
    }
    if (orderDetails?.orderStatus === "Processing") {
      return [
        { status: "Pickup Scheduled", id: 1, className: "info" },
        // { status: "Cancelled", id: 2, className: "danger" },
      ]
    }
    if (orderDetails?.orderStatus === "Pickup Scheduled") {
      return [
        { status: "Shipped", id: 1, className: "success" },
        // { status: "Cancelled", id: 2, className: "danger" },
      ]
    }
    if (orderDetails?.orderStatus === "Shipped") {
      return [{ status: "Delivered", id: 1, className: "info" }]
    }
    // if (orderDetails?.orderStatus === "Delivered") {
    //   return [{ status: "Returned", id: 1, className: "secondary" }]
    // }
  }

  const handleOrderStatusClass = status => {
    switch (status) {
      case "Pending":
      case "Shipped":
        return "info"

      case "Fulfilled":
      case "Partially Fulfilled":
        return "success"

      case "Pickup Scheduled":
      case "Delivered":
      case "Processing":
      case "In Transit":
        return "primary"

      case "Cancelled":
        return "danger"

      case "Returned":
        return "warning"
    }
  }

  const handleValidSubmit = (e, v) => {
    e.preventDefault()

    const updateOrders = {
      orderId: orderDetails?._id,
      orderStatus: v.status,
    }
    dispatch(updateOrder(updateOrders, history))
  }

  const handlePaidFun = paid => {
    const updateOrders = {
      orderId: orderDetails?._id,
      isPaid: paid === "paid" ? true : false,
    }
    dispatch(updatePayment(updateOrders))
  }

  const { copyText } = useCopyText()

  const handleCopy = text => {
    copyText(text)
  }

  const address = orderDetails && orderDetails?.shippingInfo

  const [isPdf, setIsPdf] = useState(false)

  const handleDownload = () => {
    setIsPdf(true)
  }

  useEffect(() => {
    if (isPdf === true) {
      exportComponentAsJPEG(orderDetailCompRef)
      setIsPdf(false)
    }
  }, [isPdf])

  const handleAwbNumber = (e, value) => {
    e.preventDefault()
    const updateOrders = {
      orderId: orderDetails?._id,
      ...value,
    }
    dispatch(updateOrderAwb(updateOrders))
  }

  const handleCreateShiprocketOrder = (e, value) => {
    const obj = {
      ...value,
    }
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = parseFloat(obj[key])
      }
    }
    const data = {
      ...obj,
      orderId: orderDetails?._id,
      pickupLocation: value?.pickupLocation
        ? value?.pickupLocation
        : pickupLocations[0],
    }

    dispatch(createShiprocketOrder(data))
  }

  const handleCheckPhone = () => {
    if (userDetails?.phone && address?.contactNumber) {
      if (userDetails?.phone === address?.contactNumber) {
        return "text-success"
      } else {
        return "text-danger"
      }
    }
    return "text-dark"
  }

  // shiprocket label
  const [isLabelGenerated, setIsLabelGenerated] = useState(false)

  const handleDownloadShippingLabel = () => {
    const shipmentIdArray = {
      shipmentIdArray: [
        orderDetails?.shiprocketOrderDetails?.shipment_id?.toString(),
      ],
    }

    if (orderDetails?.shiprocketOrderDetails?.shipment_id) {
      dispatch(getShiprocketLabel(shipmentIdArray))
      setIsLabelGenerated(true)
    }
  }
  const [isOpenShiprocketLabel, setIsOpenShiprocketLabel] = useState({
    status: false,
    id: "",
  })

  const handleOpenShiprocketLabelPdf = id => {
    setIsOpenShiprocketLabel({ status: true, id: id })
  }

  useEffect(() => {
    if (shiprocketLabel?.label_url) {
      handleOpenShiprocketLabelPdf()
    }
  }, [shiprocketLabel])

  const handleCloseLabelPopup = () => {
    setIsOpenShiprocketLabel({ ...isOpenShiprocketLabel, status: false })
    setIsLabelGenerated(false)
  }

  //shiprocket invoice
  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false)

  const handleDownloadShippingInvoice = () => {
    const shipmentIdArray = {
      orderIdArray: [
        orderDetails?.shiprocketOrderDetails?.order_id?.toString(),
      ],
    }
    if (orderDetails?.shiprocketOrderDetails?.order_id) {
      dispatch(getShiprocketInvoice(shipmentIdArray))
      setIsInvoiceGenerated(true)
    }
  }
  const [isOpenInvoicePopup, setIsOpenInvoicePopup] = useState({
    status: false,
    id: "",
  })

  const handleOpenShiprocketInvoicePdf = id => {
    setIsOpenInvoicePopup({ status: true, id: id })
  }

  useEffect(() => {
    if (shiprocketInvoice?.invoice_url) {
      handleOpenShiprocketInvoicePdf()
    }
  }, [shiprocketInvoice])

  const handleCloseInvoicePopup = () => {
    setIsOpenInvoicePopup({ ...isOpenShiprocketLabel, status: false })
    setIsInvoiceGenerated(false)
  }

  console.log("orderDetails", orderDetails)

  return (
    <React.Fragment>
      {isLabelGenerated && (
        <ShiprocketLabelPdfPopup
          show={isOpenShiprocketLabel?.status}
          onCloseClick={handleCloseLabelPopup}
          shipmentIds={[orderDetails?.shiprocketOrderDetails?.shipment_id]}
        />
      )}
      {isInvoiceGenerated && (
        <ShiprocketInvoicePdfPopup
          show={isOpenInvoicePopup?.status}
          onCloseClick={handleCloseInvoicePopup}
          shipmentIds={[orderDetails?.shiprocketOrderDetails?.order_id]}
        />
      )}
      <div className="page-content">
        <MetaTag title="Order Details" />
        <Container fluid>
          <Breadcrumbs title="Order" breadcrumbItem="Order Details" />
          {orderDetails?._id ? (
            <div>
              <Row>
                <div className="col-12 col-xl-8">
                  <div ref={orderDetailCompRef}>
                    <Card>
                      <CardBody className="mt-2">
                        <div
                          className={`d-flex align-items-start justify-content-between mb-30 sm:flex-col`}
                        >
                          {" "}
                          <div className="text sm:mb-10">
                            <h5 className="mb-1" style={{ fontWeight: "bold" }}>
                              {address?.name}{" "}
                              <i
                                onClick={() => handleCopy(address?.name)}
                                className="bx bx-copy mx-2 "
                                style={{ cursor: "copy" }}
                                title="Copy url link"
                              ></i>
                            </h5>
                            <Col sm="12" lg="8" className="mt-2">
                              <span className="d-flex align-items-baseline">
                                <i className="bx bx-map me-2"></i>
                                <p style={{ marginLeft: "2px" }}>
                                  <span>{address?.address}</span>
                                  <i
                                    onClick={() => handleCopy(address?.address)}
                                    className="bx bx-copy mx-2 "
                                    style={{ cursor: "copy" }}
                                    title="Copy url link"
                                  ></i>
                                  {address?.addressLine1
                                    ? `${address?.addressLine1}${","}`
                                    : ""}{" "}
                                  {address?.addressLine2
                                    ? `${address?.addressLine2}${","}`
                                    : ""}{" "}
                                  {address?.street
                                    ? `${address?.street}${","}`
                                    : ""}{" "}
                                  {address?.landmark
                                    ? `${address?.landmark}${","}`
                                    : ""}{" "}
                                  {address?.area
                                    ? `${address?.area}${","}`
                                    : ""}{" "}
                                  <br />{" "}
                                  {address?.district
                                    ? `${address?.district}${","}`
                                    : ""}{" "}
                                  {address?.state
                                    ? `${address?.state}${","}`
                                    : ""}{" "}
                                  <span>
                                    {address?.pinCode
                                      ? `${address?.pinCode}`
                                      : ""}
                                    <i
                                      onClick={() =>
                                        handleCopy(address?.pinCode)
                                      }
                                      className="bx bx-copy mx-2 "
                                      style={{ cursor: "copy" }}
                                      title="Copy text"
                                    ></i>
                                  </span>
                                </p>
                              </span>
                            </Col>
                            {address?.email && (
                              <p className="mb-0">
                                <i className="bx bx-mail-send me-2"></i>{" "}
                                {address?.email}
                                <i
                                  onClick={() => handleCopy(address?.email)}
                                  className="bx bx-copy mx-2 "
                                  style={{ cursor: "copy" }}
                                  title="Copy text"
                                ></i>
                              </p>
                            )}
                            {address?.contactNumber && (
                              <h6
                                className={`mb-0 mt-1  ${handleCheckPhone()}`}
                                style={{ fontWeight: "600" }}
                              >
                                <i className="fas fa-phone me-2"></i>{" "}
                                {address?.contactNumber}
                                <i
                                  onClick={() =>
                                    handleCopy(address?.contactNumber)
                                  }
                                  className="bx bx-copy mx-2 "
                                  style={{ cursor: "copy" }}
                                  title="Copy text"
                                ></i>
                              </h6>
                            )}
                          </div>
                          <div className="text-end">
                            <div
                              className="mb-3 ms-auto"
                              style={{ height: "30px", width: "80px" }}
                            >
                              <img
                                src={LocalLogo}
                                alt=""
                                className="h-100 w-100"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div className="text-muted">
                              <p className="mb-0">
                                Order ID :{" "}
                                <span
                                  className="text_deals_green"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {orderDetails?.orderId}
                                </span>
                              </p>
                              <span className="text-">
                                {moment(orderDetails?.createdAt).format(
                                  "DD MMMM YYYY"
                                )}
                                <br />
                                {moment(orderDetails?.createdAt).format("LT")}
                              </span>
                            </div>
                            <div style={{ width: 250 }} className="mt-3">
                              <p className="mb-3 pb-0">
                                <Badge
                                  className={`px-2 py-2 badge-soft-${
                                    orderDetails?.paymentType ===
                                    "Cash on Delivery"
                                      ? "info"
                                      : "success"
                                  }`}
                                >
                                  {orderDetails?.paymentType}
                                </Badge>
                              </p>
                            </div>
                          </div>
                        </div>

                        <Label className="mt-5">
                          Order Items ({orderDetails?.orderItems?.length}){" "}
                        </Label>
                        <OrderItemTable currecny={currecny} isPdf={isPdf} />
                        <Row>
                          <Col>
                            <hr />
                            {orderDetails?.couponCode && (
                              <p className="text-end">
                                Coupon Applied #{orderDetails?.couponCode}
                              </p>
                            )}
                            <Col
                              className=""
                              style={{
                                marginLeft: "auto",
                                width: "fit-content",
                              }}
                            >
                              <div className="table-responsive mt-4">
                                <Table className="table mb-0 myOrderTable ">
                                  <tbody>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Item Total
                                      </td>
                                      <td>:</td>
                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className="text-black  text-end">
                                          {currecny}{" "}
                                          {orderDetails?.itemsPrice?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Shipping
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-black text-end">
                                          {currecny}{" "}
                                          {orderDetails?.shippingPrice?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        COD Charge
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-black text-end">
                                          {currecny}{" "}
                                          {orderDetails?.codCharge?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Discount
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-success text-end">
                                          - {currecny}{" "}
                                          {orderDetails?.totalDiscount?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Coupon Discount
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-success text-end">
                                          - {currecny}{" "}
                                          {orderDetails?.couponDiscount?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        Tax
                                      </td>
                                      <td>:</td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <span className=" text-black text-end">
                                          {currecny}{" "}
                                          {orderDetails?.taxValue?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr style={{ borderBottom: "#fff" }}>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <h6
                                          style={{ fontWeight: "600" }}
                                          className="mb-0"
                                        >
                                          Gross Total
                                        </h6>
                                      </td>
                                      <td className="pb-0">
                                        <h6 style={{ fontWeight: "600" }}>:</h6>
                                      </td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <h6
                                          style={{ fontWeight: "600" }}
                                          className="text-end mb-0"
                                        >
                                          {currecny}{" "}
                                          {orderDetails?.totalPrice?.toFixed(2)}
                                        </h6>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="col-12 col-xl-4">
                  <UserAccount />

                  <Card>
                    <CardBody>
                      <Media body>
                        <div className="text-muted">
                          {/* <p className="mb-3 pb-0">
                            Payment Type :{" "}
                            <Badge className="px-2 py-2 badge-soft-success ">
                              {orderDetails?.paymentType}
                            </Badge>
                          </p> */}
                          {/* {orderDetails?.paymentType === "Cash on Delivery" ? (
                            <p className="mb-0 pb-0"></p>
                          ) : (
                            <p className="mb-0 pb-0">
                              Payment Id :{" "}
                              {orderDetails?.onlinePayment?.razorpay_payment_id}
                            </p>
                          )} */}
                        </div>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            {/* <thead className="table-light">
                              <tr>
                                <th></th>
                                <th className="text-start">Status</th>
                                <th className="text-start"> Update</th>
                              </tr>
                            </thead> */}

                            <div className="text-muted text-truncate">
                              {orderDetails?.paymentType !==
                                "Cash on Delivery" && (
                                <>
                                  {payment?.paymentGateway === "RazorPay" ? (
                                    <p className="mb-0 pb-0">
                                      Payment Id :{" "}
                                      {
                                        orderDetails?.onlinePayment?.razorpay
                                          ?.payment_id
                                      }
                                    </p>
                                  ) : (
                                    <p className="mb-0 pb-0">
                                      Payment Id :{" "}
                                      {
                                        orderDetails?.onlinePayment?.openPayment
                                          ?.payment_id
                                      }
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                            <tbody>
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Payment
                                </td>
                                <td
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {" "}
                                  <p className="mb-0 pb-0 d-flex align-items-center ">
                                    {orderDetails?.isPaid ? (
                                      <Badge className="px-3 py-2 badge-soft-success">
                                        Paid
                                      </Badge>
                                    ) : (
                                      <Badge className="px-3 py-2 badge-soft-danger">
                                        Not Paid
                                      </Badge>
                                    )}
                                  </p>
                                </td>
                                <td className="text-start">
                                  {!orderDetails?.isPaid && (
                                    <div className="">
                                      <Button
                                        type="submit"
                                        color="black"
                                        className={
                                          "hover-gray px-3 btn text-black"
                                        }
                                        style={{
                                          border: "1px solid whitesmoke",
                                          width: "fit-content",
                                        }}
                                        onClick={() => handlePaidFun("paid")}
                                      >
                                        Paid
                                      </Button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Order{" "}
                                </td>
                                <td
                                  className="text-start"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <p className="mb-0 pb-0 d-flex align-items-center ">
                                    <Badge
                                      className={
                                        "px-3 py-2 badge-soft-" +
                                        handleOrderStatusClass(
                                          orderDetails?.orderStatus
                                        )
                                      }
                                    >
                                      {orderDetails?.orderStatus}
                                    </Badge>
                                  </p>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {handleOrderStatus()?.length >= 1 && (
                                    <div className="">
                                      <div className="d-flex flex-wrap w-full align-items-center">
                                        <div className="d-flex">
                                          {map(
                                            handleOrderStatus(),
                                            (item, key) => (
                                              <Button
                                                key={key}
                                                type="submit"
                                                color="black"
                                                className={
                                                  "hover-gray btn mt-2 text-black"
                                                }
                                                style={{
                                                  border:
                                                    "1px solid whitesmoke",
                                                  width: "fit-content",
                                                }}
                                                onClick={e =>
                                                  handleValidSubmit(e, item)
                                                }
                                              >
                                                {item?.status}
                                              </Button>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {orderDetails?.deliveredAt && (
                          <span className="text-info">
                            Delivered At{" "}
                            {moment(orderDetails?.deliveredAt).format("ll")}
                          </span>
                        )}
                        <br />
                        {orderDetails?.shippedAt && (
                          <span className="text-info">
                            Shipped At{" "}
                            {moment(orderDetails?.shippedAt).format("ll")}
                          </span>
                        )}
                      </Media>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      {/* <p className="mb-0 pb-0" style={{ fontWeight: "bold" }}>
                        Delivery Details :
                      </p>
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <div className="d-flex flex-wrap align-items-center">
                          <Badge className="px-3 py-2 badge bg-info d-flex">
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              className="text-white font-size-12"
                              href={`https://maps.google.com/?q=${orderDetails?.userCoordinates[0]},${orderDetails?.userCoordinates[1]}`}
                            >
                              {" "}
                              View map
                            </a>
                          </Badge>
                          <Badge
                            className="px-3 py-2 mx-3 bg-info font-size-12 "
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCopy(
                                `https://maps.google.com/?q=${orderDetails?.userCoordinates[0]},${orderDetails?.userCoordinates[1]}`
                              )
                            }
                          >
                            <i
                              className="bx bx-copy"
                              style={{ cursor: "copy" }}
                              title="Copy url link"
                            ></i>
                            <span className="mx-1">Copy location</span>
                          </Badge>
                          <Badge className="px-4 py-2 mt-3  badge-soft-info font-size-14">
                            <i className="bx bx-timer mx-1 mb-0 "></i>
                            {orderDetails?.userDrivingDuration}
                          </Badge>

                          <Badge className="px-4 py-2 mx-2 mt-3 badge-soft-info font-size-14">
                            <i className="bx bx-run mx-1 "></i>
                            {orderDetails?.userDrivingDistance} km
                          </Badge>
                        </div>
                      </div>
                      <hr /> */}
                      <div className="mt-0 text-center">
                        {shipping?.isTrackingAvailable === true &&
                          !shipping?.isShiprocketOrderCreated &&
                          shipping?.orderStatus === "Shipped" && (
                            <div
                              className="w-100 mb-3"
                              style={{
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "1rem",
                              }}
                            >
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleAwbNumber(e, v)
                                }}
                                className="text-start d-flex align-items-end justify-content-between awbinput"
                              >
                                <AvField
                                  name="awbNumber"
                                  label="AWB number"
                                  type="text"
                                  style={{ height: "34px" }}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "AWB number required",
                                    },
                                  }}
                                  value={orderDetails?.awbNumber}
                                />

                                <button
                                  className="px-2 py-2 awdbtn font-size-12 "
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  type={"submit"}
                                >
                                  Add
                                </button>
                              </AvForm>
                            </div>
                          )}

                        <div
                          className="d-flex align-items-end
                            justify-content-start"
                        >
                          {shipping?.isTrackingAvailable === true &&
                            orderDetails?.awbNumber && (
                              <a
                                href={`${shipping?.trackingBaseUrl}${orderDetails?.awbNumber}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                id="invoice_download_btn"
                                className="w-100 me-3"
                              >
                                <Button
                                  className="px-3 py-2 bg-success me-3 font-size-12 w-100"
                                  style={{ cursor: "pointer", border: "none" }}
                                >
                                  {/* <i
                                    className="bx bxs-download"
                                    style={{ cursor: "pointer" }}
                                  ></i>{" "} */}
                                  Track order
                                </Button>
                              </a>
                            )}
                          <Button
                            className="px-3 py-2 bg-success font-size-12 w-100"
                            style={{ cursor: "pointer", border: "none" }}
                            onClick={handleDownload}
                          >
                            <i
                              className="bx bxs-download"
                              style={{ cursor: "pointer" }}
                            ></i>{" "}
                            Download order
                          </Button>
                        </div>
                        <div className="mt-3 text-start d-flex algin-items-center ">
                          <Button
                            className="px-3 py-2 bg-warning font-size-12 w-100 me-2"
                            style={{ cursor: "pointer", border: "none" }}
                            onClick={handleDownloadShippingLabel}
                            type="button"
                            disabled={
                              orderDetails?.orderStatus === "Pending"
                                ? true
                                : false
                            }
                          >
                            <FaStickyNote className="me-2" />
                            Shipping label
                            {shiprocketLoad && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                          </Button>
                          <Button
                            className="px-3 py-2 bg-warning font-size-12 w-100"
                            style={{ cursor: "pointer", border: "none" }}
                            onClick={handleDownloadShippingInvoice}
                            type="button"
                            disabled={
                              orderDetails?.orderStatus === "Pending"
                                ? true
                                : false
                            }
                          >
                            <FaFileInvoice className="me-2" />
                            Order Invoice
                            {shiprocketInvoiceLoad && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {logistics?.isShiprocketConnected && (
                    <Card>
                      <CardBody>
                        <div className="">
                          {!orderDetails?.isShiprocketOrderCreated ? (
                            <>
                              <Col md="12"></Col>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleCreateShiprocketOrder(e, v)
                                }}
                              >
                                <Label
                                  className="mb-3"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Package Dimensions:
                                </Label>
                                <Row md="12">
                                  <Col md="3">
                                    <AvField
                                      name="length"
                                      label="Length"
                                      type="number"
                                      value={
                                        orderDetails?.packageData?.length ||
                                        logistics?.defaultPackageDimensions
                                          ?.length
                                      }
                                    />
                                  </Col>
                                  <Col md="3">
                                    <AvField
                                      name="breadth"
                                      label="Breadth"
                                      type="number"
                                      value={
                                        orderDetails?.packageData?.breadth ||
                                        logistics?.defaultPackageDimensions
                                          ?.breadth
                                      }
                                    />
                                  </Col>
                                  <Col md="3">
                                    <AvField
                                      name="height"
                                      label="Height"
                                      type="number"
                                      value={
                                        orderDetails?.packageData?.height ||
                                        logistics?.defaultPackageDimensions
                                          ?.height
                                      }
                                    />
                                  </Col>
                                  <Col md="3">
                                    <AvField
                                      name="weight"
                                      label="Weight"
                                      type="text"
                                      value={
                                        orderDetails?.packageData?.weight ||
                                        logistics?.defaultPackageDimensions
                                          ?.weight
                                      }
                                    />
                                  </Col>
                                  <Col md="12" className="mt-2">
                                    <AvField
                                      type="select"
                                      name="pickupLocation"
                                      label="Pickup Location"
                                      value={
                                        orderDetails?.packageData
                                          ?.pickupLocation
                                      }
                                    >
                                      {pickupLocations?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </Col>
                                </Row>
                                <Button
                                  className="mt-3 px-3 my-2 bg-warning font-size-12 w-100"
                                  style={{ cursor: "pointer", border: "none" }}
                                  type="submit"
                                >
                                  <i
                                    className="bx bxs-ship"
                                    style={{ cursor: "pointer" }}
                                  ></i>{" "}
                                  Create Shipment
                                </Button>
                              </AvForm>
                            </>
                          ) : (
                            <Col>
                              <p
                                className="my-3"
                                style={{ fontWeight: "bold" }}
                              >
                                Shipment Details:{" "}
                              </p>

                              <div className="table-responsive">
                                <Table className="table mb-0 table-bordered">
                                  <tbody>
                                    {/* <tr>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Sl. No.
                                </th>
                                <th
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  Value
                                </th>
                              </tr> */}
                                    <tr>
                                      <td>Order ID: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.order_id
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Shipment ID: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.shipment_id
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Status: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.status
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Status Code: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.status_code
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>AWB Code: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.awb_code
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Courier Company ID: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.courier_company_id
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Courier Name: </td>
                                      <td>
                                        {
                                          orderDetails?.shiprocketOrderDetails
                                            ?.courier_name
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex  justify-content-center "
              style={{ height: "500px", alignItems: "center" }}
            >
              <p style={{ width: "60%" }} className="text-muted">
                Sorry for the inconvenience, but we’re unable to retrieve the
                order data right now. Please check back later. Thank you for
                your understanding!
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  order: PropTypes.object,
  match: PropTypes.any,
  onGetOrderDetail: PropTypes.func,
}

export default OrderDetail
